import { pricingData } from './pricingData';
import PriceCard from './PriceCard';

const Pricing = () => {
	return (
		<>
			<article id='pricing'>
				{pricingData.map((price) => (
					<PriceCard price={price} key={price.id} />
				))}
			</article>
			<div className='new-service'>
				<p>
				<span>Now Offering On-site Audio Recording Services</span> <br/>For soloists and small ensembles! High quality audio recordings of your performances complete with mixed and mastered digital files that can be used for online distribution and CD pressing.<br/><br/> <a href="#contact">// Please message for pricing</a>
				</p>
			</div>
			<div id='contact'>
				<p>
					CALL <a href='tel:615-925-0495'>(615)925-0495</a> for booking, questions, or additional
					service requests or please use the form below.
				</p><br/>
				<p>** rates vary for last minute and weekend bookings</p>
			</div>
		</>
	);
};

export default Pricing;
